<template>
    <div> 
        <v-card v-if="true" flat outlined  color="" 
        :height="vsh-140" width="100%" class="">
            <v-layout  class="ma-0    " fill-height > 
                <v-card height="100%" tile  outlined class=" b2"> 
                    <v-card  tile >
                        <v-btn-toggle  elevation="20"  class="ma-0" dark> 
                            <v-btn dark min-height="60" @click="ON_CLICK_MODE()"
                                width="58" :color="'secondary'" 
                                class="mb-"><v-icon size="25">mdi-monitor-dashboard</v-icon>
                            </v-btn>
                            <v-spacer/> 
                            <v-menu     
                                :close-on-content-click="true" 
                                offset-y  offset-overflow
                                :nudge-width="200" rounded="xl"
                                >
                                <template v-slot:activator="{ on, attrs }" >   
                                    <v-btn width="58" min-height="60" color="primary"  v-on="on"   class=""  >  
                                        <v-icon class="ml-2 ">arrow_drop_down</v-icon>
                                    </v-btn>  
                                </template> 

                                <v-card  v-if="ud"  
                                    color="lighten-1" class="">  
                                    <v-card-title class="secondary white--text py-" dark>
                                        Selected Action <v-spacer/> <v-btn dark icon=""><v-icon>close</v-icon></v-btn> 
                                    </v-card-title> 
                                    <v-list dense class="pt-0"> 
                                        <div v-if="AllActionItems">
                                            <v-virtual-scroll  
                                                :items="AllActionItems"
                                                :height="vsh-300"
                                                item-height="40">
                                                <template v-slot:default="{ item }">
                                                    <v-list-item @click="ITEM_ACTION(item.action,item,{menu:true})">
                                                        <v-list-item-action class="mr-2">
                                                            <v-icon>{{ item.icon }}</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content> 
                                                            <v-layout align-center> 
                                                                <v-avatar tile 
                                                                    :style="'--size:1px; --color:grey;'"
                                                                    size="18"
                                                                    class="mr-2 mbs-border"
                                                                    :color="item.color" > 
                                                                </v-avatar>
                                                                {{item.name}}
                                                            </v-layout>
                                                        </v-list-item-content>
                                                        <v-list-item-action v-if="item.menu"> 
                                                            <v-icon class="ml-2 ">mdi-chevron-down</v-icon>
                                                        </v-list-item-action> 
                                                    </v-list-item>  
                                                </template> 
                                            </v-virtual-scroll>
                                        </div>
                                        <!-- <v-divider />  -->
                                        <!-- <v-list-item :class="'b1 secondary--text text-center'" @click="DESELECT_CARD">
                                            <v-layout justify-center>
                                                Clear
                                            </v-layout>
                                        </v-list-item>  -->
                                    </v-list>  
                                </v-card>  
                            </v-menu>   
                        </v-btn-toggle>  
                    </v-card>
                    <v-card  :height="vsh-280" class="pa-2 mbs-scroll-y-hidee" flat color=" b2">
                        <v-layout fill-height column class="ma-0">
                            <v-card v-if="category_current_page>1"  @click="category_current_page--" class="mb-1 primary" dark :height="category_btn_h/1.4">
                                <v-layout class="ma-0" fill-height justify-center align-center> 
                                    <v-icon>mdi-chevron-double-left</v-icon>
                                </v-layout>
                            </v-card>
                            <v-spacer></v-spacer>
                            <span v-for="(item,index) in PaginatedItemCategories(vsh-210,'list')?
                                PaginatedItemCategories(vsh-210,'list').list:null" :key="index">
                                <v-card @click="SELECTED_ITEM_CATEGORY = item" 
                                    :color="SELECTED_ITEM_CATEGORY?SELECTED_ITEM_CATEGORY.key == item.key?'secondary lighten-1':'':''"  
                                    class="mb-1 pa-" :height="auto_category_btn_h" :width="category_btn_w" >
                                    <v-layout column fill-height justify-center  align-center class="ma-1 mbs-wrap-i"> 
                                        <span class="not-f2 font-weight-bold text-center  ">{{item.name}}</span> 
                                    </v-layout>
                                </v-card>  
                            </span> 
                            <v-spacer></v-spacer>
                            <v-card rounded :height="category_btn_h/1.4" v-if="PaginatedItemCategories(vsh-210)?
                                PaginatedItemCategories(vsh-210).pages>category_current_page:false"
                                @click="category_current_page++" class="mb-1 primary" dark >
                                <v-layout class="ma-0" fill-height justify-center align-center> 
                                    <v-icon>mdi-chevron-double-right</v-icon> 
                                    <!-- {{ 
                                        PaginatedItemCategories(vsh-210)?
                                        PaginatedItemCategories(vsh-210).pages-category_current_page:0 }} -->
                                </v-layout>
                            </v-card>  
                        </v-layout>
                    </v-card> 
                </v-card>
                <v-col class="pa-0">  
                    <v-card  :height="vsh-142" tile outlined class="mbs-scroll-y-hide   primary lighten-4" >
                        <v-toolbar color="primary"  class='mb-2' height="60" dark flat  v-if="MBS.actions.SIZE(ItemDepartments)" >
                            <v-autocomplete  flat
                                v-model="selected_departments"
                                :disabled="!AllowChangingDepartments"
                                :items="ItemDepartments"
                                item-text="name"
                                item-value="code" 
                                chips
                                dense :label="MBS.actions.SIZE(selected_departments)?'':'Select Department--'" 
                                filled outlined
                                multiple
                                small-chips  
                                color="" 
                                >
                                <template v-slot:selection="data">
                                    <v-chip :disabled="!AllowChangingDepartments"
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close color="secondary"
                                        @click="data.select" dense small
                                        @click:close="REMOVE_DEPARTMENT(data.item)" > 
                                        {{ data.item.name }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </v-toolbar>
                        <v-row :style="'--color:'+MBS.color.black+'; --size:1px; '"
                            class="ma-1 primary lighten-4" v-if="MBS.actions.SIZE(ItemByCategory)>0">
                            <v-col v-if="item_category_current_page>1" class=" pa-0 pr-1 pb-1" cols="12" sm="6" md="4"  >
                                <v-card @click="item_category_current_page--" flat  class=" pa-   "   min-height="60" height="100%" 
                                width="100%" >
                                    <v-layout fill-height justify-center align-center class="ma-0 pa-1 mbs-border">
                                        <v-icon color="black">mdi-chevron-double-left</v-icon>
                                    </v-layout>
                                </v-card>  
                            </v-col>

                            <v-col class="pa-0 pr-1 pb-1" cols="12" sm="6" md="4"  v-for="(item,index) in 
                                PaginatedItemByCategory(vsh-210)?PaginatedItemByCategory(vsh-210).list:null" :key="index">
                                <v-card flat @click="SELECT_ITEM(item)"  class=" pa- " min-height="60" height="100%" width="100%" >
                                    <v-layout fill-height justify-center align-center class="ma-0 pa-1 mbs-border">
                                        <span class="not-f2 text-center">{{item.name}}  </span>
                                    </v-layout>
                                </v-card>  
                            </v-col>
                            
                            <v-col v-if="PaginatedItemByCategory(vsh-210)?
                                PaginatedItemByCategory(vsh-210).pages>item_category_current_page:false" 
                                class="pa-0 pr-1 pb-1" cols="12" sm="6" md="4"  >
                                <v-card @click="item_category_current_page++"  class=" pa-1 " min-height="60" height="100%" width="100%" >
                                    <v-layout fill-height justify-center align-center class="ma-0 pa-1">
                                        <v-icon color="black">mdi-chevron-double-right</v-icon>
                                    </v-layout>
                                </v-card>  
                            </v-col>
                        </v-row>
                        <v-layout v-else-if="SELECTED_ITEM_CATEGORY" fill-height align-center justify-center class="ma-0  grey--text">
                            <v-chip large color="b1" class="mx-1" >
                                <v-icon class="mx-2" color="grey">warning</v-icon>
                                <v-layout row  class="grey--text ma-0 mr-2 py-1" justify-centers align-center>
                                    No Items in Selected  <strong class="mx-1">{{ SELECTED_ITEM_CATEGORY.name }}</strong> Category
                                </v-layout>
                            </v-chip>
                            
                        </v-layout>
                        <v-layout v-else fill-height align-center justify-center class="ma-0  grey--text">
                            
                            <v-chip  large class="px-5" >
                                <v-icon>warning</v-icon> 
                                <div class="not-f5 ml-2 grey--text" v-if="MBS.actions.SIZE(ItemCategories)">Select Item Category </div>
                                <div class="not-f5 ml-2 grey--text" v-else>No Item Category !</div>
                            </v-chip>
                        </v-layout>
                    </v-card> 
                </v-col> 
            </v-layout> 
        </v-card> 

        <!-- //DIALOGS -->
        <div>
            <!-- ACTION MENU -->
            <v-dialog
                v-model="dialog_action_menu"
                scrollable   
                :persistent="true" :overlay="false"
                max-width="300px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="SELECTED_ACTION_MENU" > 
                    <v-card-title :class="'primary'">
                        <v-avatar tile 
                            :style="'--size:1px; --color:white;'"
                            size="20"
                            class="mr-2 mbs-border"
                            :color="SELECTED_ACTION_MENU.color" > 
                        </v-avatar>
                        <div class="font-weight-bold b2--text"> 
                            <span >{{SELECTED_ACTION_MENU.name}}</span> 
                        </div> 
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_action_menu=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>
                    
                    <v-list dense class=" "> 
                        <div v-if="SELECTED_ACTION_MENU.menu">
                            <v-list-item 
                                v-for="(item,index) in SELECTED_ACTION_MENU.menu.items" 
                                @click="
                                    ITEM_ACTION(item.action,item,{menu:true})
                                    dialog_action_menu=false
                                ">
                                <v-list-item-action><v-icon>{{ item.icon }}</v-icon></v-list-item-action>
                                <v-list-item-content> 
                                    {{item.name}}
                                </v-list-item-content>
                                <v-list-item-action v-if="item.menu"> 
                                    <v-icon class="ml-2 ">mdi-chevron-down</v-icon>
                                </v-list-item-action> 
                            </v-list-item>  
                        </div> 
                    </v-list> 
                    <v-card-text>
                        
                    </v-card-text>  
                </v-card> 
            </v-dialog> 
            
        </div>
    </div>
</template> 
<script> 

    import DATA from "../../../plugins/DATA";
    import {mapState} from "vuex" 
    import DB from "../../../plugins/DB";
    const PAGE_NAME = "SELECT_ITEMS"

    export default {
        props:['height','action_items','allow_changing_department',
        'selected_department_codes','selling_model','title','text','items',
        'input_discount_amount','input_discount_percentage'],
        data(){
            return{
                show:false,   

                category_current_page:1, 
                category_btn_h:60, 
                category_btn_w:100, 
                item_category_current_page:1, 
                item_category_btn_h:60, 
                item_category_btn_w:100,

                dialog_action_menu:false, 
                selected_departments:null,
                SELECTED_ITEM_CATEGORY:null, 
                SELECTED_ACTION_MENU:null, 
                 
            }
        },
        created(){ 
            try {
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);   
                this.MBS.events.$on('MBS_KEYBOARD', this.MBS_KEYBOARD);  
                this.ON_UPDATE_DEPARTMENT(this.selected_departments)
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){ 
            this.INITIALIZE_ITEMS_CATEGORIES()
            this.INITIALIZE_ITEMS_DEPARTMENTS()  
        },
        computed:{
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                ItemDepartments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],

                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                
               
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                
                LocalSelectedSalesItems: state=> state.locals[(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values).toUpperCase()],
                JoinedLocalSelectedSalesItems: state=> state.join[(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values).toUpperCase()], 
            }), 

            //data
            ItemsData(){
                const Items = this.Items
                const JoinedItems = this.JoinedItems
                const data = JoinedItems?JoinedItems:Items
                return data 
            },
            ItemsFiltered(){
                const items = this.ItemsData
                const departments = this.selected_departments
                const categories = this.FilteredItemCategories 
                if(!items){return null}
                if(!this.MBS.actions.SIZE(departments)){return items}
                let filtered = items.filter(item=>{
                    let index = departments?departments.indexOf(item.item_department_code):0
                    let index2 = categories?categories.findIndex(category=>category.code == item.category_code):0
                    return (index!=-1)||(index2!=-1)
                })
                return filtered

            },    
            ItemByCategory(){  
                const category = this.SELECTED_ITEM_CATEGORY 
                this.item_category_current_page = 1
                const items = this.ItemsFiltered
                if(!category || !items){return null}
                const filtered = items.filter(item=>item.category_code==category.code)
                if(!filtered){return null}
                const list = [...filtered]
                list.sort(this.MBS.actions.dynamicSort("name",true))   
                return list  
            },  
            PaginatedItemByCategory(){  
                let vs = this.vs
                let xs = vs?vs.xs:false 
                let sm = vs?vs.sm:false 
                let md = vs?vs.mdAndUp:false
                let rows = 1
                if (sm) {
                    rows = 2  
                }if (md) {
                    rows = 3
                } 
                const current_page =this.item_category_current_page
                const items =this.ItemByCategory
                return (size,type)=>{
                    let row_size = 73 / rows
                    let page_size = Math.trunc(size/row_size)
                    if (current_page == 1) {
                        page_size=page_size+1
                    } 
                    if(!items){return null}
                    const list = this.MBS.actions.pagination(items,current_page,page_size)  
                    return list  
                }
            }, 
            LocalSelectedSalesItemsData(){
                const Items = this.LocalSelectedSalesItems
                const JoinedItems = this.JoinedLocalSelectedSalesItems
                const data = JoinedItems?JoinedItems:Items
                return data 
            },
            ReversedLocalSelectedSalesItemsData(){   
                const items = this.LocalSelectedSalesItemsData 
                if(!items) {return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("selecting_list_key",true))
                return list.reverse()
            },  
            SortedItemCategories(){  
                let items =this.ItemCategories
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true))   
                return list  
            }, 
            FilteredItemCategories(){  
                let items =this.SortedItemCategories
                let selected =this.selected_departments
                if(!items){return null}
                if(!this.MBS.actions.SIZE(selected)){return items}
                let filtered = items.filter(item=>{
                    let index = selected?selected.indexOf(item.item_department_code):-1
                    return index != -1
                })
                return filtered  
            }, 
            PaginatedItemCategories(){  
                let current_page =this.category_current_page
                let items =this.FilteredItemCategories
                return (size,type)=>{
                    let page_size = Math.trunc(size/73)
                    if (current_page == 1) {
                        page_size=page_size+1
                    } 
                    if(!items){return null}
                    let list = this.MBS.actions.pagination(items,current_page,page_size)  
                    return list  
                }
            },   
            SELECTED_ITEMS(){
                const settings = this.CompanySettings
                const selling_list_descend = settings?settings.selling_list_descend:null
                const items = this.LocalSelectedSalesItemsData 
                const reversed = this.ReversedLocalSelectedSalesItemsData  
                return selling_list_descend?reversed:items
            }, 
            AllActionItems(){
                const action_items = this.action_items 
                return action_items
            },  
            auto_category_btn_h(){
                let vsh = this.vsh
                let height = 55
                if (vsh<500) {
                    height = 46
                }else if (vsh<550) {
                    height = 50
                }else if (vsh<600) {
                    height = 51
                }else if (vsh<700) {
                    height = 55
                }else if (vsh<800) {
                    height = 56
                }else if (vsh<880) {
                    height = 57
                }else if (vsh<900) {
                    height = 58
                } 
                return height
            },
            AllowChangingDepartments(){
                const allow_changing_department = this.allow_changing_department 
                return allow_changing_department 
            },
            
              
        },
        methods:{ 
            REMOVE_DEPARTMENT (item) { 
                const index = this.selected_departments.indexOf(item.code)
                if (index >= 0) this.selected_departments.splice(index, 1)
            },
            async ITEM_ACTION(action,item,option){
                try {    
                    const isMenu = option?option.menu:false 
                    const hasMenu = item?item.menu:false  
                    if (isMenu && hasMenu) {
                        this.SELECTED_ACTION_MENU=item
                        this.dialog_action_menu=true
                        return
                    } 
                    if (action) {
                        this.$emit('item_action',action,item,option)    
                    }else{
                        this.MBS.actions.dialog(null,true,false,"Coming Soon!..","Current not working. Try again later.",null,"OK") 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            INITIALIZE_ITEMS_CATEGORIES(){
                try { 
                    const categories = this.FilteredItemCategories
                    const first_category = categories?categories[0]:null 
                     if (first_category) { 
                        this.SELECTED_ITEM_CATEGORY = first_category 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIALIZE_ITEMS_CATEGORIES',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            INITIALIZE_ITEMS_DEPARTMENTS(){
                try { 
                    const selected_department_codes =  this.selected_department_codes  
                    if(selected_department_codes){
                        this.selected_departments = [...selected_department_codes]
                    }else{} 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIALIZE_ITEMS_DEPARTMENTS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SELECT_ITEM(item){
                try { 
                    this.$emit('select_item',item)   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_CLICK_MODE(item){
                try { 
                    this.$emit('click_mode')   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_CLICK_MODE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_UPDATE_DEPARTMENT(value){
                try { 
                    this.$emit("update_department",value)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_UPDATE_DEPARTMENT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
              

            //
            CHECK_ITEMS_STOCK_SETTINGS(payload){
                try { 
                    let response = {
                        stop_selling:false, 
                        ordering_remind:false, 
                        stock_settings:null,
                        stop_sell_items:null,
                        ordering_remind_items:null,
                        payload:payload
                    }  


                    let items = payload?payload.items:null  
                    let outlet_code = payload?payload.outlet_code:null   
                    let notify = payload?payload.notify:null   
                    if (!items) { 
                        return response
                    }

                    let stock_settings = []
                    let stop_sell_items = []
                    let ordering_remind_items = []
                    items.forEach(item => {
                        let stock_setting = this.CHECK_ITEM_STOCK_SETTINGS({
                            outlet_code:outlet_code,
                            item:item,
                            quantity:item.quantity, 
                        }) 
                        stock_settings.push(stock_setting)
                        if (stock_setting) {
                            if (!stock_setting.allow_sell) {
                               stop_sell_items.push({
                                    ...stock_setting,
                                    name:item.name
                                }) 
                               response.stop_selling = true
                            }
                            if (stock_setting.ordering_remind) {
                                ordering_remind_items.push({
                                    ...stock_setting,
                                    name:item.name
                                }) 
                                response.ordering_remind = true
                            }
                        }
                    });

                    if (notify) {
                        let stop_sell_size = this.MBS.actions.SIZE(stop_sell_items)
                        let ordering_remind_size = this.MBS.actions.SIZE(ordering_remind_items)
                        let stop_sell_names = ''
                        let ordering_remind_names = ''
                        stop_sell_items.forEach(item => {
                            stop_sell_names = stop_sell_names+"\n \n Item: "+item.name+"\n Quantity: "+item.quantity+"\n Stop selling level: "+item.stop_selling_level
                        });
                        ordering_remind_items.forEach(item => {
                            ordering_remind_names = ordering_remind_names+"\n \n Item: "+item.name+"\n Quantity: "+item.quantity+"\n Ordering level: "+item.ordering_remind_level
                        });

                        if (stop_sell_size&&ordering_remind_size) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"OUT OF STOCK",
                                text:"Some of the selected item(s) are out of stock. \n\n OUT OF STOCK ITEM(S): "+stop_sell_names
                                    +" \n\n ORDERING REMIND ITEM(S): "+ordering_remind_names    
                                    +".",
                                btnYes:"OKAY"
                            })
                        }else if (stop_sell_size) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"OUT OF STOCK",
                                text:"Some of the selected item(s) are out of stock. \n\n OUT OF STOCK ITEM(S): "+stop_sell_names  
                                    +".",
                                btnYes:"OKAY"
                            })
                        }else if (ordering_remind_size) {
                            this.MBS.actions.dialog({
                                show:true,
                                title:"LOW ON STOCK LEVEL",
                                text:"Some of the selected item(s) are below order remind level. \n\n ORDERING REMIND ITEM(S): "+ordering_remind_names    
                                    +".",
                                btnYes:"OKAY"
                            })
                        }  
                    } 
                    return {
                        ...response, 
                        allow_sell:!response.stop_selling,
                        stock_settings:stock_settings,
                        stop_sell_items:stop_sell_items,
                        ordering_remind_items:ordering_remind_items,
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_ITEMS_STOCK_SETTINGS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CHECK_ITEM_STOCK_SETTINGS(payload){
                try { 
                    let response = {
                        allow_sell:false,
                        ordering_remind:false,
                        quantity:null,
                        stop_selling_level:null,
                        ordering_remind_level:null,
                        active_settings:null,
                        payload:payload
                    }   
                    let item_selected = payload?payload.item:null 
                    let item_selected_name = item_selected?item_selected.name:null 
                    let item_kits = item_selected?item_selected.item_kits:null 
                    let outlet_code = payload?payload.outlet_code:null  
                    let quantity = this.toNumber(payload?payload.quantity:null)
                    let notify = payload?payload.notify:null  
                    quantity = quantity?quantity:1
                    if (!item_selected) { 
                        return response
                    }
                    //
                    let sold_item = this.getSoldItem(item_selected.key)
                    let sold_quantity = this.toNumber(sold_item?sold_item.sold_quantity:null) 
                    let settings = this.CompanySettings
                    let stock_settings = settings?settings.stock_settings:null
                    let outlets_settings = stock_settings?stock_settings.outlets:null
                    let outlet_stock_settings = outlets_settings?outlets_settings[outlet_code]:null

                    let item_stock_settings = item_selected?item_selected.stock_settings:null 
                    let active_settings = item_stock_settings?item_stock_settings:outlet_stock_settings?outlet_stock_settings:stock_settings

                    response.active_settings = active_settings  

                    if (active_settings && item_selected) {
                        let stop_selling_level = this.toNumber(active_settings.stop_selling_level)
                        let ordering_remind_level = this.toNumber(active_settings.ordering_remind_level)
                        let item_quantity = this.toNumber(item_selected['number_quantity'])
                        if (outlet_code) {
                            item_quantity = this.toNumber(item_selected[outlet_code+'_number_quantity'])
                        }
                        item_quantity = item_quantity-sold_quantity
                        response.sold_quantity = sold_quantity
                        response.quantity = item_quantity
                        response.stop_selling_level = stop_selling_level
                        response.ordering_remind_level = ordering_remind_level
                        
                        if ((item_quantity-quantity) < stop_selling_level) {
                            response.allow_sell = false
                            if (notify && !item_kits) {
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:"OUT OF STOCK",
                                    text:"NAME: "+item_selected_name 
                                        +"\n ITEM QUANTITY: "+item_quantity
                                        +"\n STOP SELLING LEVEL: "+stop_selling_level
                                        +"\n \n   The selected item is below selling level.",
                                    btnYes:"OKAY"
                                })  
                            }
                        }else if ((item_quantity-quantity) < ordering_remind_level) {
                            response.allow_sell = true
                            response.ordering_remind = true
                            if (notify && !item_kits) {
                                this.MBS.actions.dialog({
                                    show:true,
                                    title:"LOW ON STOCK LEVEL",
                                    text:"NAME: "+item_selected_name 
                                        +"\n ITEM QUANTITY: "+item_quantity
                                        +"\n ORDER REMIND LEVEL: "+ordering_remind_level
                                        +"\n \n   The selected item is below ordering level.", 
                                    btnYes:"OKAY"
                                }) 
                            }
                        }else{
                            response.allow_sell = true
                        }  

                        if (item_kits) { 
                            response.allow_sell = true
                        }
                    }
                    if (!active_settings) {
                        response.allow_sell = true
                    }
                    return response
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_ITEM_STOCK_SETTINGS',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 

            //---------------[MAIN FUNCTIONS]------------   
            ON_QR_SCAN(code){
                try {
                    if (code) {  
                        
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{  
                             
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            MBS_KEYBOARD(key){ 
                let focused_field = this.focused_field
                const field_value = focused_field?focused_field.value:null

                const ref_inputs = this.$refs
                if (key ) {
                    let value = key.value 
                    if (field_value) { 
                        if (field_value == "current_selected_item_quantity") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.quantity = (""+this.current_selected_item.quantity).slice(0,-1)
                            } else {
                                this.current_selected_item.quantity =this.current_selected_item.quantity+""+value 
                            }
                        }else if (field_value == "current_selected_item_discount") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.discount = (""+this.current_selected_item.discount).slice(0,-1)
                            } else {
                                this.current_selected_item.discount =this.current_selected_item.discount+""+value 
                            }
                        }else{
                            if(!this.input){this.input={}}
                            if (value=="BACK_SPACE") {
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                            }else{
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value 
                            }
                            setTimeout(() => {
                                this.input = {...this.input}
                            }, 1); 
                        }
                        
                    }
                }
            },
            

        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
                this.MBS.events.$off('MBS_KEYBOARD', this.MBS_KEYBOARD);      
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{  
            SELECTED_ITEMS(value){
                 
            },
            CompanySettings(value){
                if (value) {   
                    this.INITIALIZE_ITEMS_CATEGORIES()
                    this.INITIALIZE_ITEMS_DEPARTMENTS()
                }
            }, 
            selected_departments(value){
                this.ON_UPDATE_DEPARTMENT(value)
                if (value) {   
                    this.INITIALIZE_ITEMS_CATEGORIES() 
                }
            }, 
        } 

    }
</script>
<style>
    .selected {
        background-color: #a8dadc;
    }
    .checked {
        background-color: #dadada;
    }
    
</style> 